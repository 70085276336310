<template>
  <div class="container">
    <PrimePreloader v-if="loading"/>

    <div v-else class="mt-5 p-2">
      <h4 class="text-center">Академический календарь</h4>

      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Курс</label>
        <div class="col-md-9">
          <select class="form-control form-select"
                  @input="changeStudyCourse($event)">
            <option selected value="0">Выберите курс</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
      </div>


      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Уровень
          обучения</label>
        <div class="col-md-9">

          <div v-if="study_course == 0">
            <p><strong style="color: red">Курс не выбран</strong></p>
          </div>
          <div v-else>
            <select class="form-control form-select"
                    @input="changeStudyLevel($event)">
              <option
                  v-for="(item, index) in [{id: 0, name: 'Выберите уровень обучения'}, ...adviserChoose_form.studyLevels]"
                  :value="item.id"
                  :key="index">{{ item.name }}
              </option>
            </select>
          </div>


        </div>
      </div>




      <div class="my-4 text-center">
        <Button icon="pi pi-table" label="Вывести календарь" class="p-button-rounded"
                :loading="formationStatus"
                @click="formCalendar"
        />
      </div>


      <div class="calendars mt-4" v-if="calendar?.length > 0">
        <TabView ref="tabview1">
          <TabPanel header="Учебный календарь">

            <div class="row mt-2 mb-2">
              <div class="col-md-5">Курс</div>
              <div class="col-md-7">

                <Dropdown
                    :options="studyCourses"
                    optionLabel="name" optionValue="name"
                    :placeholder="calendar[0].education_calendar[0].study_course" class="w100p" disabled/>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-5">Уровень обучения</div>
              <div class="col-md-7">
                <Dropdown
                    :options="adviserChoose_form.studyLevels"
                    optionValue="name"
                    optionLabel="name"
                    :placeholder="adviserChoose_form.studyLevels.find(i=>i.id == calendar[0]?.education_calendar[0]?.study_level_id)?.name"
                    class="w100p" disabled/>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-5">Начало</div>
              <div class="col-md-7">

                <input type="datetime-local" class="form-control" placeholder="Начало"
                       :value="calendar[0].education_calendar[0].start_date" disabled>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-5">Конец</div>
              <div class="col-md-7">
                <input type="datetime-local" class="form-control" placeholder="Конец"
                       :value="calendar[0].education_calendar[0].end_date" disabled>
              </div>
            </div>

          </TabPanel>
          <TabPanel header="Календарь праздников">

            <div v-for="(holiday, index) in calendar[0].holiday_calendar" :key="index">
              <div class="row mt-4">
                <label class="col-md-3 col-form-label">{{ holiday.name }}</label>
                <div class="col-md-3">
                  <label class="">Начало праздника</label>
                  <input type="datetime-local" class="form-control" :value="holiday.start_date"
                         disabled>
                </div>
                <div class="col-md-3">
                  <label class="">Конец праздника</label>
                  <input type="datetime-local" class="form-control" :value="holiday.end_date"
                         disabled>
                </div>
              </div>
            </div>


          </TabPanel>
          <TabPanel header="Календарь семестра">

            <div v-for="(s, sIndex) in calendar[0].semester_calendar" :key="sIndex" class="border p-3 my-2">
              <div class="row mt-2">
                <div class="col-md-3">Учебный календарь</div>
                <div class="col-md-4">
                  <select class="form-control form-select">
                    <option
                        v-for="(option, index) in educationCalendar_form.educationCalendar"
                        :value="option.id" :key="index"
                        :selected="s.education_calendar_id === option.id" disabled>
                      {{ option.study_level }} {{ option.study_course }} курс
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-3">Семестр</div>
                <div class="col-md-4">

                  <select class="form-control form-select" >

                    <option v-for="(item, index) in semesters" :key="index" :value="item.id" :selected="item.id == s.semester_type_id" disabled>
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-3">Начало выбора расписания</div>
                <div class="col-md-4">
                  <input type="datetime-local" class="form-control" :value="s.start_choose_schedule"
                         disabled>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-3">Конец выбора расписания</div>
                <div class="col-md-4">
                  <input type="datetime-local" class="form-control" :value="s.end_choose_schedule"
                         disabled>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-3">Начало ВСК1</div>
                <div class="col-md-4">
                  <input type="datetime-local" class="form-control" :value="s.vsk1_start_date"
                         disabled>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-3">Конец ВСК1</div>
                <div class="col-md-4">
                  <input type="datetime-local" class="form-control" :value="s.vsk1_end_date"
                         disabled>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-3">Конец ВСК2</div>
                <div class="col-md-4">
                  <input type="datetime-local" class="form-control" :value="s.vsk2_end_date"
                         disabled>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-3">Начало экзаменов</div>
                <div class="col-md-4">
                  <input type="datetime-local" class="form-control" :value="s.start_exam_date"
                         disabled>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-3">Конец экзаменов</div>
                <div class="col-md-4">
                  <input type="datetime-local" class="form-control" :value="s.end_exam_date"
                         disabled>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-3">Начало каникул</div>
                <div class="col-md-4">
                  <input type="datetime-local" class="form-control" :value="s.start_holiday_day"
                         disabled>
                </div>
              </div>

            </div>


          </TabPanel>


          <TabPanel header="Календарь летнего семестра">


            <div v-for="(element, elementIndex) in calendar[0].semester_summer_calendar" :key="elementIndex" class="my-4 border rounded-3 p-2">



              <div class="row mt-2 mb-2">
                <div class="col-md-5">Учебный календарь</div>
                <div class="col-md-7">

<!--                  <Dropdown-->
<!--                      :options="educationCalendar_form.educationCalendar.filter(i=>i.study_level_id == studyLevelId)"-->
<!--                      optionLabel="study_course" optionValue="id"-->
<!--                      :placeholder="element?.education_calendar_id != null ? getOptionLabel(element.education_calendar_id) : 'Выберите учебный календарь'"-->
<!--                      class="w100p"/>-->
                  <select class="form-control form-select">
                    <option
                        v-for="(option, index) in [{id:0, study_level_id: '', study_course: null, name: 'Выберите'},...educationCalendar_form.educationCalendar]"
                        :value="option.id" :key="index"
                        :selected="element.education_calendar_id === option.id" disabled>
                      {{ option.study_level }} {{ option.study_course }} курс
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-5">Начало</div>
                <div class="col-md-7">
                  <input type="datetime-local" class="form-control" placeholder="Начало"
                         :value="element.start_date" disabled>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-5">Конец</div>
                <div class="col-md-7">
                  <input type="datetime-local" class="form-control" placeholder="Конец"
                         :value="element.end_date" disabled>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-5">Начало регистрации</div>
                <div class="col-md-7">
                  <input type="datetime-local" class="form-control" placeholder="Начало"
                         :value="element.start_registration_date" disabled>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-5">Конец регистрации</div>
                <div class="col-md-7">
                  <input type="datetime-local" class="form-control" placeholder="Конец"
                         :value="element.end_registration_date" disabled>
                </div>
              </div>



            </div>



          </TabPanel>

          <TabPanel header="Календарь выбора расписания">
            <div v-for="(s, sIndex) in calendar[0].choose_discipline_calendar" :key="sIndex"
                 class="border p-3 my-2">


              <div class="row mt-4">
                <label class="col-md-3 col-form-label">Учебный календарь</label>
                <div class="col-md-4">
                  <select class="form-control form-select">
                    <option
                        v-for="(option, index) in educationCalendar_form.educationCalendar"
                        :value="option.id" :key="index"
                        :selected="s.education_calendar_id === option.id" disabled>
                      {{ option.study_level }} {{ option.study_course }} курс
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-3">Начало выбора расписания</div>
                <div class="col-md-4">
                  <input type="datetime-local" class="form-control" placeholder="Начало"
                         :value="s.start_date" disabled>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-3">Конец выбора расписания</div>
                <div class="col-md-4">
                  <input type="datetime-local" class="form-control" placeholder="Конец"
                         :value="s.end_date" disabled>
                </div>
              </div>


            </div>
          </TabPanel>
        </TabView>
      </div>




        <div class="my-3" v-if="icalendar == null">

            <div class="row" v-if="decryptCheckRole('officeRegistrationHead') || decryptCheckRole('admin')">
              <div class="mb-2">
                <Button label="Дать доступ" icon="pi pi-check" class="p-button-success p-button-rounded mt-2 me-2"
                        @click="giveAccess()"/>

<!--                <Button label="Отменить доступ" icon="pi pi-times" class="p-button-danger p-button-rounded mt-2"-->
<!--                        @click="giveAccess(0)"/>-->
              </div>


            </div>




      </div>
      <div v-else>
        <div class="row">
          <a :href="icalendar?.file">Скачать календарь</a>

        </div>
      </div>



    </div>

  </div>
</template>


<script>
import userInfo from "../services/userInfo"
import httpClient from "@/services/http.service"
import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"
import {mapActions, mapState} from "vuex";
import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";
import {getCookie} from "@/utils/helpers/cookies.helpers";
export default {
  name: "AcademicCalendar",

  data() {
    return {
      //user: null,
      icalendar: {},
      calendar: [],
      loading: true,
      studyCourse: 0,
      studyLevelId: 0,
      formationStatus: false,
      studyCourses: [
        {
          name: 1
        },
        {
          name: 2
        },
        {
          name: 3
        },
        {
          name: 4
        },
      ],
      semesters: [],
    }
  },
  computed: {
    ...mapState('adviserChoose', ['adviserChoose_form']),
    ...mapState('educationCalendar', ['educationCalendar_form']),
  },
  methods: {
    decryptCheckRole,
    ...mapActions('adviserChoose', ['GET_STUDY_LEVELS']),
    ...mapActions('educationCalendar', ['GET_EDUCATION_CALENDAR']),
    ...mapActions('semesterCalendar', ['GET_SEMESTER']),
    changeStudyCourse(e) {
      this.studyCourse = e.target.value
      console.log(this.studyCourse, 'this.studyCourse')
    },
    changeStudyLevel(e) {
      this.studyLevelId = e.target.value
    },

    // checkRole(i) {
    //   const roles = JSON.parse(getCookie('ROLES')) || []
    //   if (!roles.length && getCookie('ROLE')) {
    //     roles.push(getCookie('ROLE'))
    //   }
    //   return roles.includes(i)
    // },

    async formCalendar() {
      this.formationStatus = true
      await this.getCalendar()
      this.formationStatus = false
    },

    async giveAccess() {

      try {
        const {status, data} = await httpClient.post(`academic_calendar/icalendar/give-access?access_token=${getCookie('ACCESS_TOKEN')}`)
        if (status === 200 && data) {
          this.$message({text: 'Календарь создан'})
          await this.getIcalendar()
          return true
        }
      } catch (e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      }
      return false
    },

    // async downloadCalendar() {
    //   try {
    //     const {status, data} = await httpClient.get(`academic_calendar/icalendar/get-icalendar`)
    //     if (status === 200 && data) {
    //       console.log(data)
    //       //this.calendar = data
    //       return true
    //     }
    //   } catch (e) {
    //     this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
    //   }
    //   return false
    // },

    async getCalendar() {
      try {
        const {status, data} = await httpClient.get(`academic_calendar/icalendar/get-calendar-list?study_level_id=${this.studyLevelId}&study_course=${this.studyCourse}
        &access_token=${getCookie('ACCESS_TOKEN')}`)
        if (status === 200) {
          this.calendar = data
          console.log(this.calendar, 'getCalendar')
          return true
        }
      } catch (e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      }
      return false
    },
    async getIcalendar() {
      try {
        const {status, data} = await httpClient.get(`academic_calendar/icalendar/index?access_token=${getCookie('ACCESS_TOKEN')}`)
        if (status === 200) {
          console.log(data, 'sdgsdg')
          this.icalendar = data
          return true
        }
      } catch (e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      }
      return false
    },


  },
  async mounted() {
    await this.GET_STUDY_LEVELS()
    await this.GET_EDUCATION_CALENDAR()
    this.semesters = await this.GET_SEMESTER()
    await this.getIcalendar()
    this.loading = false
  }
}

</script>
<style scoped>

</style>

